import React from "react"
import { Box, Flex, FlexProps, IconButton } from "@chakra-ui/core"
import Body from "../typography/Body"

type PaginationProps = FlexProps & {
  page: number
  pageCount: number
  onNext?: VoidFunction
  onPrevious?: VoidFunction
}

export default function Pagination({
  page,
  pageCount,
  onNext,
  onPrevious,
  ...props
}: PaginationProps) {
  return (
    <Flex align="center" justify="space-between" {...props}>
      <IconButton
        aria-label="Go to previous page"
        icon="chevron-left"
        color="dawn"
        onClick={onPrevious}
        isDisabled={page <= 1}
        variant="outline"
      />
      <Body
        size="md"
        fontWeight="bold"
        whiteSpace="nowrap"
      >{`Page ${page} of ${pageCount}`}</Body>
      <IconButton
        aria-label="Go to Next page"
        icon="chevron-right"
        color="dawn"
        onClick={onNext}
        isDisabled={page >= pageCount}
        variant="outline"
      />
    </Flex>
  )
}
