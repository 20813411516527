import React from "react"
import { Box, BoxProps, Flex } from "@chakra-ui/core"
import { Link } from "gatsby"
import GatsbyImage, { FluidObject } from "gatsby-image"
import styled from "@emotion/styled"
import Body from "../typography/Body"
import { bp } from "../../utils/MediaQueries"

type ArticleBlockItemProps = BoxProps & {
  image: FluidObject
  title: string
  subtext?: React.ReactNode
  to: string
}

const StyledImage = styled(GatsbyImage)`
  width: auto;
  max-height: 300px;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
`

export default function ArticleBlockItem({
  image,
  title,
  subtext,
  to,
  ...props
}: ArticleBlockItemProps) {
  return (
    <Link to={to} style={{ flexBasis: "100%" }}>
      <Box textAlign="left" {...props}>
        <Box h={bp("18.75rem", "auto")}>
          <StyledImage fluid={image} />
        </Box>
        <Flex
          flexDirection="column"
          background="#eee"
          p="0px 20px 20px 20px"
          borderBottomLeftRadius="0.5rem"
          borderBottomRightRadius="0.5rem"
        >
          <Body
            size="md"
            // color="#999"
            fontWeight="bold"
            textTransform="uppercase"
            mt={bp("1rem", "1rem")}
          >
            Electric Tricycles
          </Body>
          <Body
            size="md"
            color="night"
            fontWeight="bold"
            mt={bp("1rem", "1rem")}
          >
            {title}
          </Body>
          {/* {subtext && (
          <Body size="md" color="dawn" mt={bp("0.4825rem", "0.5rem")}>
            {subtext}
          </Body>
        )} */}
        </Flex>
      </Box>
    </Link>
  )
}
