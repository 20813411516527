import React, { ReactNode } from "react"
import styled from "@emotion/styled"
import { Box, BoxProps, Tag } from "@chakra-ui/core"
import { Link } from "gatsby"
import { FluidObject } from "gatsby-image"
import BackgroundImage, { IBackgroundImageProps } from "gatsby-background-image"
import Heading from "../typography/Heading"
import SmallCaps from "../typography/SmallCaps"
import Body from "../typography/Body"
import { bp } from "../../utils/MediaQueries"

type ArticleBlockProps = BoxProps & {
  image: FluidObject
  title: string
  subtext?: ReactNode
  tag?: string
  to: string
}

type BackgroundProps = IBackgroundImageProps & {
  size?: string
  positionX?: string
  positionY?: string
}

const StyledBackground = styled(BackgroundImage)<BackgroundProps>`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  background-size: ${(props) => props.size || "cover"};
  background-position: ${(props) =>
    `${props.positionX || "center"} ${props.positionY || "center"}`};
`

export default function ArticleBlock({
  image,
  title,
  subtext,
  tag,
  to,
  ...props
}: ArticleBlockProps) {
  return (
    <Box
      pos="relative"
      h={bp("18.75rem", "33.125rem")}
      color="white"
      {...props}
    >
      <Link to={to}>
        <StyledBackground backgroundColor="white" positionY="top" fluid={image}>
          <Box
            pos="absolute"
            top={0}
            left={0}
            zIndex={-1}
            w="100%"
            h="100%"
            backgroundColor="rgba(44, 46, 52, 0.31)"
          />
          <Box
            pt={bp("2.9375rem", "6.5938rem")}
            pb={bp("2.6875rem", "5.6969rem")}
            px={bp("1.375rem", "7.475rem")}
            zIndex={2}
          >
            {tag && (
              <Tag
                backgroundColor="sunrise"
                px="0.75rem"
                py="0.5rem"
                borderRadius="0.5rem"
                mb={bp("0.9375rem", "2rem")}
              >
                <SmallCaps size="lg" color="white" fontWeight="bold">
                  {tag}
                </SmallCaps>
              </Tag>
            )}
            <Heading
              as="h3"
              size="3"
              color="white"
              fontWeight="bold"
              mb={bp("16.5625rem", "13rem")}
            >
              {title}
            </Heading>
            {subtext && <Body fontWeight="bold">{subtext}</Body>}
          </Box>
        </StyledBackground>
      </Link>
    </Box>
  )
}
